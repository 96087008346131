









































































import {Component, Vue} from 'vue-property-decorator';
import ViewDesign from "@/components/global/ViewDesign.vue";

@Component({  components: {ViewDesign}})
export default class Imprint extends Vue {

}

